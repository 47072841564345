import React, { FC, useMemo, useState } from 'react';
import { TAuthDialogContentProps } from './@types';
import PgTypo from 'Components/PgTypo';
import { Box, makeStyles, alpha } from '@material-ui/core';
import { useStoreState } from 'Stores';
import PgButton from 'Components/PgButton';
import { FONT_FAMILY } from 'Theme/themeConstants';
import useAsyncTask from 'Hooks/useAsyncTask';
import UserModel from 'Models/User';

export interface PickInterestsProps extends TAuthDialogContentProps {}

const PickInterests: FC<PickInterestsProps> = (props) => {
    const { onDone } = props;

    const { tagsSummaries, appUser } = useStoreState(({ App: { tagsSummaries }, AuthStore: { appUser } }) => ({ tagsSummaries, appUser }));

    const [selectedTags, setSelectedTags] = useState<string[]>([]);

    const selectedTagSet = useMemo(() => new Set(selectedTags), [selectedTags]);

    const handleSelectTag = (tag: string, isSelected: boolean) => () => {
        setSelectedTags((prev) => {
            if (isSelected) return prev.filter((t) => t !== tag);
            return [...prev, tag];
        });
    };

    const handleDone = useAsyncTask(async () => {
        if (!appUser?.id) return;
        await UserModel.updateUser({ id: appUser.id, tagIds: selectedTags });
        onDone();
    });

    const classes = useStyles();

    return (
        <div className={classes.title}>
            <PgTypo h3 align="center">
                Pick Interests
            </PgTypo>
            <PgTypo b2 align="center" className={classes.subtitle}>
                Select at least three options.
            </PgTypo>
            <Box className={classes.interestsContainer}>
                {tagsSummaries.map((tag) => {
                    const isSelected = selectedTagSet.has(tag.id);
                    return (
                        <PgButton
                            key={tag.id}
                            primary={isSelected}
                            secondary={!isSelected}
                            style={isSelected ? { backgroundColor: tag.background, color: tag.color, borderColor: tag.background } : undefined}
                            onClick={handleSelectTag(tag.id, isSelected)}
                            className={classes.interestButton}
                        >
                            {tag.name}
                        </PgButton>
                    );
                })}
            </Box>
            <PgButton primary fullWidth disabled={selectedTags.length < 3} onClick={handleDone.run} className={classes.continueButton}>
                Continue
            </PgButton>
        </div>
    );
};

export default PickInterests;

const useStyles = makeStyles((theme) => ({
    title: { marginBottom: theme.spacing(1.5) },
    subtitle: { marginBottom: theme.spacing(5) },
    interestsContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(6, 1fr)',
        gap: theme.spacing(4, 1.5),
        marginBottom: 136,
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            gap: theme.spacing(2, 1.5),
            marginBottom: 126,
        },
    },
    interestButton: {
        ...theme.typography.body1,
        fontFamily: FONT_FAMILY.tertiary,
        lineHeight: '100%',
        textTransform: 'unset',
        padding: theme.spacing(1.75, 1.5),
        gridColumn: 'span 2',
        '&:nth-last-child(-n+2)': {
            gridColumn: 'span 3',
        },
    },
    continueButton: {
        backgroundColor: theme.palette.common.white,
        '&:disabled': { backgroundColor: alpha(theme.palette.common.white, 0.4) + '!important' },
        padding: theme.spacing(2),
        maxWidth: 343,
        margin: '0 auto',
    },
}));

const INTERESTS = [
    {
        id: 1,
        name: 'Interest 1',
    },
];
