import React, { FC, PropsWithChildren, useMemo } from 'react';
import { IconButton, makeStyles, Theme } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';
import SVGCIcon from 'Components/SVGComponents/SVGCIcon';
import PG_LOGO from 'Assets/images/PLAYGROUND_WORDMARK-S_WHITE_2.svg';
import TO_LEFT_ARROW from 'Assets/icons/sidebar/to_left.svg';
import { THEME_PALETTE } from 'Theme/themeConstants';
import PgTypo from 'Components/PgTypo';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import { useStoreState } from 'Stores';
import helpers from 'Utils/helpers';
import UserAvatar from 'Components/UserAvatar';
import { generatePath, Link, useHistory } from 'react-router-dom';
import { APP_ROUTES } from 'Routes';
import { TUserCommunity } from 'Models/User';

export interface LeftMobileDrawerProps {
    open: boolean;
    onClose: () => void;
}

const Title: FC = ({ children }) => {
    return <PgTypo b5>{children}</PgTypo>;
};

const LeftMobileDrawer: FC<LeftMobileDrawerProps> = (props) => {
    const { open, onClose } = props;

    const { appUser } = useStoreState(({ AuthStore: { appUser } }) => ({ appUser }));

    const { joinedCommunities, managedCommunities } = useMemo(
        () => ({
            joinedCommunities: appUser?.followsCommunities || [],
            managedCommunities: appUser?.userCommunities || [],
        }),
        [appUser?.followsCommunities, appUser?.userCommunities],
    );

    const classes = useStyles({ open });

    const { isDeviceSm } = useMediaQuery();

    const history = useHistory();

    const handleCommunityClick = (community: TUserCommunity) => {
        history.push(generatePath(APP_ROUTES.COMMUNITY_DETAIL.path, { communityId: community?.slug || community?.id || '#' }));
        onClose();
    };

    if (!isDeviceSm) return null;

    return (
        <div className={classes.root}>
            <div className={classes.topBar}>
                <div className={classes.logoWrapper}>
                    <SVGCIcon icon="icon-menu" />
                    <img src={PG_LOGO} alt="pg-logo" className={classes.logo} />
                </div>
                <IconButton onClick={onClose} className={classes.toLeftArrowButton}>
                    <img src={TO_LEFT_ARROW} alt="to-left-arrow" className={classes.toLeftArrow} />
                </IconButton>
            </div>
            <div className={classes.navContentWrapper}>
                {!!joinedCommunities?.length && (
                    <div className={classes.navContent}>
                        <Title>COMMUNITIES I’VE JOINED</Title>
                        {joinedCommunities.map((community) => (
                            <CommunityItem community={community} classes={classes} key={community.id} handleCommunityClick={handleCommunityClick} />
                        ))}
                    </div>
                )}

                {!!managedCommunities?.length && (
                    <div className={classes.navContent}>
                        <Title>COMMUNITIES I MANAGE</Title>
                        {managedCommunities.map((community) => (
                            <CommunityItem community={community} classes={classes} key={community.id} handleCommunityClick={handleCommunityClick} />
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

interface CommunityItemProps {
    community: TUserCommunity;
    classes: Record<string, string>;
    handleCommunityClick: (communityId: TUserCommunity) => void;
}

const CommunityItem: FC<CommunityItemProps> = (props) => {
    const { community, classes, handleCommunityClick } = props;

    const data = useMemo(
        () => ({
            name: community?.name || '',
            image: helpers.getPictureUrl(community?._profileImages?.[0]),
        }),
        [community],
    );

    return (
        <button key={community.id} className={classes.communityItem} onClick={() => handleCommunityClick(community)}>
            <UserAvatar name={data.name} size={40} url={data.image} className={classes.communityLogo} />
            <PgTypo c1>{data.name}</PgTypo>
        </button>
    );
};

export default LeftMobileDrawer;

const useStyles = makeStyles<Theme, Pick<LeftMobileDrawerProps, 'open'>>((theme) => ({
    root: {
        width: '100vw',
        height: '100dvh',
        position: 'fixed',
        inset: 0,
        zIndex: 1200,
        transition: 'transform 0.3s ease-in-out',
        transform: ({ open }) => (open ? 'translateX(0)' : 'translateX(-200vw)'),
    },
    topBar: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(1.25, 2),
        borderBottom: `1px solid ${THEME_PALETTE.grey.A900}`,
        backgroundColor: theme.palette.common.black,
    },
    logoWrapper: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
    },
    logo: {
        width: '160px',
        height: 'auto',
    },
    toLeftArrow: { width: '24px', height: 'auto' },
    toLeftArrowButton: { padding: 0 },
    navContent: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2.5),
        color: theme.palette.common.white,
    },
    communityLogo: {
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        border: `1px solid ${alpha(THEME_PALETTE.grey.A900, 0.5)}`,
    },
    communityItem: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1.5),
        position: 'relative',
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
        color: theme.palette.common.white,
    },
    communityLink: {
        opacity: 0,
        position: 'absolute',
        inset: 0,
        zIndex: 1,
    },
    navContentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(5),
        height: '100%',
        backgroundColor: theme.palette.common.black,
        width: `calc(100% - 50px)`,
        padding: theme.spacing(2.5),
        overflowY: 'auto',
    },
}));
