import { IconButton, makeStyles, Theme } from '@material-ui/core';
import React, { useMemo } from 'react';
import TO_RIGHT_ARROW from 'Assets/icons/sidebar/to_right_white.svg';
import { useStoreState } from 'Stores';
import UserAvatar from 'Components/UserAvatar';
import helpers from 'Utils/helpers';
import UserModel from 'Models/User';
import PgTypo from 'Components/PgTypo';
import { FONT_FAMILY, FONT_WEIGHTS } from 'Theme/themeConstants';
import PgButton from 'Components/PgButton';
import { generatePath, useHistory } from 'react-router-dom';
import { APP_ROUTES } from 'Routes';
import UserMenu from './UserMenu';

export interface RightMobileDrawerProps {
    open: boolean;
    onClose: () => void;
}

const RightMobileDrawer: React.FC<RightMobileDrawerProps> = (props) => {
    const { open, onClose } = props;

    const { appUser } = useStoreState(({ AuthStore: { appUser } }) => ({ appUser }));

    const user = useMemo(
        () => ({
            name: `${appUser?.firstName || ''} ${appUser?.lastName || ''}`,
            username: appUser?.slug || '',
            imageAlt: UserModel.getUserName(appUser),
            image: helpers.getPictureUrl(appUser?._profileImages?.[0]),
        }),
        [appUser],
    );

    const classes = useStyles({ open });

    const history = useHistory();

    const handleViewEditProfile = () => {
        history.push(generatePath(APP_ROUTES.USER_DETAIL.path, { id: appUser?.slug ?? appUser?.id ?? '#' }));
        onClose();
    };

    if (!appUser) return null;

    return (
        <div className={classes.root}>
            <IconButton onClick={onClose} className={classes.toRightArrowButton}>
                <img src={TO_RIGHT_ARROW} alt="to-right-arrow" className={classes.toRightArrow} />
            </IconButton>
            <div className={classes.content}>
                <div className={classes.userInfo}>
                    <UserAvatar name={user.imageAlt} url={user.image} size="220px" />
                    <PgTypo className={classes.userSlug}>@{user.username}</PgTypo>
                    <PgTypo className={classes.userName}>{user.name}</PgTypo>
                    <PgButton secondary color="secondary" className={classes.viewEditProfileButton} onClick={handleViewEditProfile}>
                        View / Edit Profile
                    </PgButton>
                </div>
                <UserMenu onClose={onClose} />
            </div>
        </div>
    );
};

export default RightMobileDrawer;

const useStyles = makeStyles<Theme, Pick<RightMobileDrawerProps, 'open'>>((theme) => ({
    root: {
        width: '100vw',
        height: '100dvh',
        position: 'fixed',
        inset: 0,
        zIndex: 1200,
        transition: 'transform 0.3s ease-in-out',
        transform: ({ open }) => (open ? 'translateX(0)' : 'translateX(200vw)'),
        display: 'flex',
    },
    toRightArrow: { width: '24px', height: 'auto' },
    toRightArrowButton: {
        padding: `10px 16px`,
        backgroundColor: theme.palette.common.black,
        height: 'fit-content',
        '&:hover': { backgroundColor: theme.palette.common.black },
    },
    content: {
        width: '100%',
        height: '100%',
        backgroundColor: theme.palette.common.black,
        overflowY: 'auto',
    },
    userInfo: {
        padding: `32px 54px`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: theme.palette.common.white,
    },
    userSlug: {
        fontSize: '20px',
        lineHeight: '120%',
        letterSpacing: '0.4px',
        fontFamily: FONT_FAMILY.secondary,
        padding: `26px 0 8px`,
        fontWeight: FONT_WEIGHTS.fontWeightBold,
    },
    userName: {
        fontSize: '12px',
        lineHeight: '120%',
        letterSpacing: '0.24px',
        fontFamily: FONT_FAMILY.primary,
        paddingBottom: '20px',
    },
    viewEditProfileButton: {
        color: theme.palette.common.white,
        borderColor: theme.palette.common.white,
        '&:hover': { borderColor: theme.palette.common.white },
    },
}));
