import DialogWrapper from 'Components/DialogWrapper';
import { Box, createStyles, IconButton, makeStyles, Theme } from '@material-ui/core';
import React, { FC, useRef } from 'react';
import PgIcon from 'Components/PgIcon';
import PgTypo from 'Components/PgTypo';
import Carousel, { CarouselRef } from 'Components/Carousel';
import { DIVIDER_BORDER_BLACK, FONT_FAMILY, FONT_WEIGHTS } from 'Theme/themeConstants';
import PgButton from 'Components/PgButton';

import ONBOARD_IMG_1 from 'Assets/images/onboard/onboard_1.png';
import ONBOARD_IMG_2 from 'Assets/images/onboard/onboard_2.svg';
import ONBOARD_IMG_3 from 'Assets/images/onboard/onboard_3.svg';

interface OnboardProps {
    open: boolean;
    onClose?: () => void;
}

const Onboard: FC<OnboardProps> = (props) => {
    const { open, onClose } = props;

    const classes = useStyles();

    const carouselRef = useRef<CarouselRef>(null);

    const handleNextClick = () => {
        if (carouselRef.current?.state?.activeIndex === ONBOARD_ITEMS.length - 1) onClose?.();
        else carouselRef.current?.slideNext();
    };

    return (
        <DialogWrapper open={open} variant="light" PaperProps={{ className: classes.root }}>
            <IconButton className={classes.closeIcon} onClick={onClose}>
                <PgIcon icon="icon-close" style={{ fontSize: 16 }} />
            </IconButton>
            <Box display="flex" flexDirection="column" alignItems="center" py={4}>
                <PgTypo c3 className={classes.title}>
                    Welcome to Playground
                </PgTypo>
                <Carousel
                    ref={carouselRef}
                    items={ONBOARD_ITEMS.map((o) => {
                        return (
                            <Box key={o.id} display="flex" flexDirection="column" alignItems="center" px={2.5}>
                                <PgTypo className={classes.carouselItemTitle}>{o.title}</PgTypo>
                                <PgTypo className={classes.carouselItemSubtitle}>{o.subtitle}</PgTypo>
                                <img src={o.img} alt={o.title} className={classes.carouselItemImg} style={o.imgStyle} />
                            </Box>
                        );
                    })}
                />
                <Box width="100%" display="flex" flexDirection="column" alignItems="center" gridGap={20} mt={2.5} px={2.5}>
                    <PgButton primary fullWidth className={classes.nextButton} onClick={handleNextClick}>
                        NEXT
                    </PgButton>
                    <PgButton quaternary onClick={onClose}>
                        Skip all onboarding
                    </PgButton>
                </Box>
            </Box>
        </DialogWrapper>
    );
};

export default Onboard;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: 345,
            minHeight: 'unset !important',
            borderRadius: 'unset',
        },
        closeIcon: {
            position: 'absolute',
            top: theme.spacing(3),
            right: theme.spacing(3),
            padding: 0,
        },
        title: {
            marginBottom: theme.spacing(0.5),
            color: theme.palette.text.disabled,
        },
        carouselItemTitle: {
            fontSize: 20,
            lineHeight: '28px',
            fontWeight: FONT_WEIGHTS.fontWeightBold,
            letterSpacing: '0.4px',
            fontFamily: FONT_FAMILY.secondary,
            marginBottom: theme.spacing(1),
        },
        carouselItemSubtitle: {
            fontSize: 14,
            lineHeight: '22px',
            letterSpacing: '0.28px',
            textAlign: 'center',
            marginBottom: theme.spacing(2.5),
        },
        carouselItemImg: {
            width: 'auto',
            height: 268,
            objectFit: 'cover',
        },
        nextButton: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
            '&:hover, &:focus': { backgroundColor: theme.palette.common.black },
        },
    }),
);

const ONBOARD_ITEMS = [
    {
        id: 1,
        title: 'Create drops',
        subtitle: 'Drop events, media and merch to be discovered by your communities.',
        img: ONBOARD_IMG_1,
        imgStyle: { border: DIVIDER_BORDER_BLACK },
    },
    {
        id: 2,
        title: 'Discover communities',
        subtitle: 'Join or create a creative community.',
        img: ONBOARD_IMG_2,
    },
    {
        id: 3,
        title: 'Start conversations',
        subtitle: 'Join discussion threads to start chatting.',
        img: ONBOARD_IMG_3,
    },
];
