import { ButtonBase, makeStyles } from '@material-ui/core';
import { CONTACT_GMAIL_LINK } from 'Constants/links';
import PgIcon from 'Components/PgIcon';
import SVGCIcon from 'Components/SVGComponents/SVGCIcon';
import WalletList from 'Features/AppHeader/WalletList';
import React, { isValidElement, useMemo } from 'react';
import { generatePath, useHistory } from 'react-router';
import { APP_ROUTES } from 'Routes';
import { ACCOUNTS_ROUTES } from 'Routes/AccountNavigation';
import { User } from 'Models/User';
import { useStoreState } from 'Stores';
import TICKET_ICON from 'Assets/icons/ticket.svg';
import SETTINGS_ICON from 'Assets/icons/settings.svg';
import { MobileMenuItem } from 'Dialogs/MobileMenu/LogOutMenu';
import PgTypo from 'Components/PgTypo';
import Dark from 'Features/DarkTheme';
import { FONT_WEIGHTS } from 'Theme/themeConstants';
import useAuth from 'Features/Auth/useAuth';

interface UserMenuProps {
    onClose: () => void;
}

const UserMenu: React.FC<UserMenuProps> = (props) => {
    const { onClose } = props;

    const classes = useStyles();

    const { appUser } = useStoreState(({ AuthStore: { appUser } }) => ({ appUser }));

    const history = useHistory();

    const { logout } = useAuth();

    const handleWalletListItemClick = () => {
        onClose();
    };

    const menuOptions = useMemo(() => getMenuOptions(appUser, handleWalletListItemClick, classes), [appUser, classes]);

    const handleOptionClick = (option: MobileMenuItem) => () => {
        if (option.isExternal) window.open(option.link, '_blank');
        else history.push(option?.link || '#');
        onClose();
    };

    const handleLogout = () => {
        logout();
        history.push(APP_ROUTES.HOME_PAGE.path);
        onClose();
    };

    return (
        <Dark>
            <div>
                {menuOptions.map((option) => {
                    if (isValidElement(option)) return option;
                    const _option = option as MobileMenuItem;
                    return (
                        <ButtonBase key={_option.id} onClick={handleOptionClick(_option)} className={classes.optionButton}>
                            {_option.icon}
                            <PgTypo className={classes.optionName}>{_option.name}</PgTypo>
                        </ButtonBase>
                    );
                })}
                <ButtonBase className={classes.optionButton} onClick={handleLogout}>
                    <PgIcon icon="icon-collapse" style={{ fontSize: 20 }} />
                    <PgTypo className={classes.logout}>Logout</PgTypo>
                </ButtonBase>
            </div>
        </Dark>
    );
};

export default UserMenu;

const useStyles = makeStyles((theme) => ({
    optionButton: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: theme.spacing(1.5),
        padding: '18px 20px',
        borderTop: `1px solid ${theme.palette.text.disabled}`,
    },
    optionName: {
        fontSize: '14px',
        lineHeight: '120%',
        letterSpacing: '0.28px',
        color: theme.palette.common.white,
    },
    walletList: {
        width: '100%',
        padding: '18px 20px',
        borderTop: `1px solid ${theme.palette.text.disabled}`,
        borderBottom: 'none',
    },
    logout: {
        fontSize: '14px',
        lineHeight: '120%',
        letterSpacing: '0.28px',
        color: theme.palette.common.white,
        textDecoration: 'underline',
        fontWeight: FONT_WEIGHTS.fontWeightBold,
    },
}));

const getMenuOptions = (
    appUser?: User,
    onWalletListItemClick?: () => void,
    classes?: Record<string, string>,
): Array<MobileMenuItem | JSX.Element> => [
    {
        id: 7,
        name: 'Orders',
        link: ACCOUNTS_ROUTES.TICKETS.path,
        icon: <img src={TICKET_ICON} alt="ticket-icon" width={20} />,
    },
    {
        id: 8,
        name: 'Saved',
        link: generatePath(APP_ROUTES.FAVORITES.path, { slug: appUser?.slug ?? appUser?.id ?? '#' }),
        icon: <PgIcon icon="icon-save" style={{ fontSize: 20 }} />,
    },
    <WalletList
        onListItemClick={onWalletListItemClick}
        iconProps={{ width: 20 }}
        textClassName={classes?.optionName}
        rootClassName={classes?.walletList}
    />,
    {
        id: 4,
        name: 'Settings',
        link: ACCOUNTS_ROUTES.SETTINGS.path,
        icon: <img src={SETTINGS_ICON} alt="settings-icon" width={20} />,
    },
    {
        id: 5,
        icon: <SVGCIcon icon="icon-add-user" color="primary" width={20} />,
        name: 'Invites',
        link: ACCOUNTS_ROUTES.INVITE_FRIENDS.path,
    },
    {
        id: 6,
        icon: <PgIcon icon="icon-question" style={{ fontSize: 20 }} />,
        name: 'Support',
        link: CONTACT_GMAIL_LINK,
        isExternal: true,
    },
];
