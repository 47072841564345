import React, { useContext, useState, useRef, useEffect } from 'react';
import AuthDialogContent from 'Features/Auth/AuthDialogContent';
import { IconButton, makeStyles, Theme } from '@material-ui/core';
import PgIcon from 'Components/PgIcon';
import { AppDialogContext, IAppDialog } from '../../Contexts/AppDialogContext';
import { Step } from './@types';
import clsx from 'clsx';
import { APP_HEADER_HEIGHT } from 'Features/AppHeader';
import { darkTheme } from 'Theme';
import { useStoreActions } from 'Stores';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import Onboard from 'Dialogs/Onboard';

let promiseResolve: (value?: Step) => void;
let promiseReject: (reason: any) => void;
const useAuthDialog = (onDone?: (step?: Step) => void) => {
    const { showDialog, hideDialog, setDialogContent } = useContext(AppDialogContext);
    const { setSidebarHidden } = useStoreActions(({ App: { setSidebarHidden } }) => ({ setSidebarHidden }));
    const [step, setStep] = useState<Step>();
    const stepRef = useRef<Step>();
    stepRef.current = step;
    const [meta, setMeta] = useState<any>();
    const firstRenderTracker = useRef(true);
    const classes = useStyles({ meta });
    const { isDeviceSm } = useMediaQuery();

    const changeStep = (step: Step) => {
        setStep(step);
    };

    const onClose = () => {
        promiseReject?.(step);
        onDone?.();
        hideDialog();
        setSidebarHidden(false);
        setStep(undefined);
    };

    const handleOnBoardClose = () => {
        hideDialog();
        setStep(undefined);
    };

    const onDialogDone = () => {
        promiseResolve?.(step);
        onDone?.(step);
        setSidebarHidden(false);
        if (step === 'pick-interests') setDialogContent(<Onboard open={true} onClose={handleOnBoardClose} />, { showOverlayContent: true });
        else hideDialog();
    };

    const onBack = () => {
        if (stepRef.current === 'login' || stepRef.current === 'signup') {
            setMeta((_m = {}) => ({ ..._m, showBack: false }));
            setStep('web3-email-check');
        } else if (stepRef.current === 'reset-password') {
            setStep('login');
        }
    };

    const showAuthDialog = (meta?: any, _step?: Step) => {
        return new Promise<Step | undefined>((resolve, reject) => {
            promiseResolve = resolve;
            promiseReject = reject;
            showDialog(
                <AuthDialogContent step={_step ?? step ?? 'login'} changeStep={changeStep} meta={meta} onDone={onDialogDone} setMeta={setMeta} />,
                showDialogProps(classes, onBack, onClose, meta),
            );
        });
    };

    useEffect(() => {
        // showAuthDialog() will execute only  if step changes and not when the component renders
        /*  if (firstRenderTracker.current) {
          firstRenderTracker.current = false;
        } else {
          showAuthDialog(meta);
        } */
        if (!step) return;
        setDialogContent(
            <AuthDialogContent step={step ?? 'login'} changeStep={changeStep} meta={meta} onDone={onDialogDone} setMeta={setMeta} />,
            showDialogProps(classes, onBack, onClose, meta),
        );
    }, [step, meta]);

    const openDialog = async (page: Step = 'login', meta?: any): Promise<Step | undefined> => {
        let step: Step = page;
        setMeta(meta ?? {});
        setStep(step);
        if (meta?.variant !== 'small') setSidebarHidden(true); // Assumption is that unless explicitly set, auth dialog opens in full viewport width.
        return showAuthDialog(meta, step);
    };

    return { openDialog };
};

export default useAuthDialog;

const useStyles = makeStyles<Theme, { meta: any }>((theme) => ({
    dialogHeader: {
        position: 'absolute',
        right: 0,
        top: 0,
        left: 0,
        padding: 10,
        width: '100%',
        // [theme.breakpoints.down('sm')]: { display: 'none' },
    },
    dialogContent: {
        padding: 8,
    },
    btnContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        '& button': {
            zIndex: 2,
        },
    },
    fullScreenDialogBtnContainer: {
        padding: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            padding: 0,
        },
    },
    fullScreenDialogPaper: {
        width: '100vw',
        padding: 0,
        borderRadius: 0,
        margin: `${APP_HEADER_HEIGHT}px 0 0 0`,
        height: `calc(100vh - ${APP_HEADER_HEIGHT}px)`,
        [theme.breakpoints.down('sm')]: {
            margin: 0,
            height: '100vh',
            // margin: '50px 0 0 0',
            // height: `calc(100vh - 50px)`, // When in mobile mode, the 'logoutMenu' component will be open and the header in that component is only 50px tall.
            '& .MuiDialogContent-root': { height: '100%' },
        },
    },
    closeButton: { borderRadius: 0, padding: 0 },
}));

const showDialogProps = (classes: Record<string, string>, onBack: () => void, onClose: () => void, meta: any): Partial<IAppDialog> => ({
    headerProps: {
        isCloseButton: false,
        headerClasses: classes.dialogHeader,
        headerContent: (
            <div className={clsx(classes.btnContainer, { [classes.fullScreenDialogBtnContainer]: meta?.variant === 'full' })}>
                {meta?.showBack ? (
                    <IconButton onClick={onBack} style={{ display: meta?.showBack ? 'block' : 'none' }}>
                        <PgIcon icon="icon-arrow-back" />
                    </IconButton>
                ) : null}
                <div />
                {!meta?.hideClose ? (
                    <IconButton onClick={onClose} className={classes.closeButton}>
                        <PgIcon icon="icon-close" color="contrast" />
                    </IconButton>
                ) : null}
            </div>
        ),
    },
    PaperProps: {
        style: {
            backgroundColor: 'black !important',
        },
    },
    contentProps: {
        className: classes.dialogContent,
    },
    isActionCloseButton: false,
    ...(meta?.variant === 'small' ? smallDialogProps : getFullScreenModalProps(classes)),
    // disableBackdropClick: true,
    onClose(event, reason) {
        console.log('dialog close by ', reason);
    },
    disableEscapeKeyDown: true,
    scroll: 'body',
    open: true,
});
const smallDialogProps: Partial<IAppDialog> = {
    style: {
        zIndex: darkTheme.zIndex.modal + 2,
    },
    PaperProps: {
        style: {
            width: 400,
            padding: 16,
            borderRadius: 0,
            backgroundColor: 'black',
        },
    },
    BackdropProps: {
        style: {
            backdropFilter: 'blur(3px)',
        },
    },
    contentProps: undefined,
};
const getFullScreenModalProps = (classes: Record<string, string>): Partial<IAppDialog> => ({
    PaperProps: {
        className: classes.fullScreenDialogPaper,
        style: {
            maxWidth: 'unset',
        },
    },
    hideBackdrop: true,
    contentProps: {
        style: { padding: 0 },
    },
    disableEnforceFocus: true,
});
