import { hooks as mataMaskHooks, metaMask } from 'Web3/Connector/Metamask';
import { hooks as coinbaseHooks, coinbaseWallet } from 'Web3/Connector/Coinbase';
import { hooks as wallletConnectHooks, walletConnect } from 'Web3/Connector/WalletConnect';
import { getAddChainParameters } from 'Web3/Chains';
import config from 'config';
import { Step } from './@types';
import { useContext, useEffect, useState } from 'react';
import useWeb3Actions from './useWeb3Actions';
import Web3Model from 'Models/Web3';
import { WalletType as WebWalletType } from 'Models/Web3/@types';
import useAsyncTask from 'Hooks/useAsyncTask';
import UserModel, { User } from 'Models/User';
import { CheckEmailExistFormData } from 'Forms/CheckEmailExist';
import { useStoreActions, useStoreDispatch, useStoreState } from 'Stores';
import utils from 'Utils';
import { get, omit } from 'lodash';
import { SignUpFormData } from 'Forms/SignUp';
import useAuth from './useAuth';
import { LoginFormData } from 'Forms/Login';
import AuthModel from 'Models/Auth';
import { useSnackbar, VariantType } from 'notistack';
import ToastMessage, { ToastMessageProps } from 'Components/ToastMessage';
import MESSAGES from 'Utils/Messages';
import { registrationComplete } from 'Analytics/trackers';
import { useProvider as usePhantomProvider } from 'Web3/Connector/Phantom';
import { ANALYTICS_USER_EVENT } from 'Analytics/analyticActions';
import { useLocation } from 'react-router';
import { useMediaQuery } from '../../Hooks/useMediaQuery';
import { useWallet as useSuiWallet } from '@suiet/wallet-kit';

export enum WalletType {
    MetaMask = 'MetaMask',
    Coinbase = 'Coinbase',
    WalletConnect = 'WalletConnect',
    Phantom = 'Phantom',
    Sui = 'Sui',
}

const WalletMap = {
    [WalletType.MetaMask]: WebWalletType.METAMASK,
    [WalletType.Coinbase]: WebWalletType.COINBASE,
    [WalletType.Phantom]: WebWalletType.PHANTOM,
    [WalletType.WalletConnect]: WebWalletType.WALLETCONNECT,
    [WalletType.Sui]: WebWalletType.SUI,
};

const { useAccounts: useMetaMaskAccounts, useProvider: useMetaMaskProvider } = mataMaskHooks;
const { useAccounts: useCoinbaseAccounts, useProvider: useCoinbaseProvider } = coinbaseHooks;
const { useAccounts: useWalletConnectAccounts, useProvider: useWalletConnectProvider } = wallletConnectHooks;

export interface AsyncTaskProps<T> {
    run: (arg: Partial<T>) => Promise<any>;
    status: 'IDEAL' | 'PROCESSING' | 'ERROR' | 'SUCCESS';
    message: string;
    reset: () => void;
}

export interface TWalletConnectHookProps {
    handleWalletSelect: (wallet: WalletType, appLink?: string) => void;
    handleEmailExist: AsyncTaskProps<CheckEmailExistFormData>;
    handleUserSignUp: AsyncTaskProps<SignUpFormData>;
    handleUserLogIn: AsyncTaskProps<LoginFormData>;
    handleErrorBackClick: () => void;
    setWalletMeta: React.Dispatch<React.SetStateAction<Record<string, any>>>;
    walletMeta: Record<string, any>;
    setWalletStep: React.Dispatch<React.SetStateAction<Step | undefined>>;
    walletStep: Step | undefined;
}

function useWalletConnect(onDone: () => void): TWalletConnectHookProps {
    const metaMaskAccounts = useMetaMaskAccounts();
    const metaMaskProvider = useMetaMaskProvider();

    const coinbaseAccounts = useCoinbaseAccounts();
    const coinbaseProvider = useCoinbaseProvider();

    const walletConnectAccounts = useWalletConnectAccounts();
    const walletConnectProvider = useWalletConnectProvider();

    const [phantomAccount, setPhantomAccount] = useState<string>();
    const phantomProvider = usePhantomProvider();

    const suiWallet = useSuiWallet();

    const { enqueueSnackbar } = useSnackbar();
    const showToast = (msg: ToastMessageProps | string, variant: VariantType = 'error') =>
        enqueueSnackbar(ToastMessage(typeof msg === 'string' ? { title: msg } : msg), { variant, autoHideDuration: 8000 });

    const { getMetaMaskSignature, getCoinBaseSignature, getWalletConnectSignature, getPhantomSignature, getSuiSignature } = useWeb3Actions();

    // ---For analytics---
    const dispatch = useStoreDispatch();
    const { pathname } = useLocation();
    // --------------------

    const [walletMeta, setWalletMeta] = useState<TWalletConnectHookProps['walletMeta']>({});
    const [walletStep, setWalletStep] = useState<Step>();
    const [user, setUser] = useState<User | undefined>(undefined);
    const [wallet, setWallet] = useState<WalletType | undefined>(undefined);
    const [pubAddress, setPubAddress] = useState<string | undefined>(undefined);

    const { updateUser, authenticate, setAppUser, setIsAuthenticated } = useStoreActions(
        ({ AuthStore: { updateUser, authenticate, setAppUser, setIsAuthenticated } }) => ({
            updateUser,
            authenticate,
            setAppUser,
            setIsAuthenticated,
        }),
    );
    const { appUser, web3State } = useStoreState(({ AuthStore: { appUser }, App: { web3State } }) => ({ appUser, web3State }));

    const { requestEmailVerification } = useAuth();

    const { isDeviceSm } = useMediaQuery();

    useEffect(() => {
        metaMask.connectEagerly();
        coinbaseWallet.connectEagerly();
        walletConnect.connectEagerly();
    }, []);

    const SignerMap: Record<WalletType, Function> = {
        [WalletType.MetaMask]: getMetaMaskSignature,
        [WalletType.Coinbase]: getCoinBaseSignature,
        [WalletType.Phantom]: getPhantomSignature,
        [WalletType.WalletConnect]: getWalletConnectSignature,
        [WalletType.Sui]: getSuiSignature,
    };

    useEffect(() => {
        if (walletStep === 'wallet-connecting' && wallet) {
            if (metaMaskAccounts?.length && metaMaskProvider && wallet === WalletType.MetaMask) {
                connectWallet(metaMaskAccounts?.[0] ?? '', getMetaMaskSignature, undefined, WebWalletType.METAMASK);
            } else if (coinbaseAccounts?.length && coinbaseProvider && wallet === WalletType.Coinbase) {
                connectWallet(coinbaseAccounts?.[0] ?? '', getCoinBaseSignature, undefined, WebWalletType.COINBASE);
            } else if (walletConnectAccounts?.length && walletConnectProvider && wallet === WalletType.WalletConnect) {
                connectWallet(walletConnectAccounts?.[0] ?? '', getWalletConnectSignature, undefined, WebWalletType.WALLETCONNECT);
            } else if (suiWallet.account?.address && suiWallet.account.publicKey && wallet === WalletType.Sui) {
                connectWallet(suiWallet.account.address, getSuiSignature, undefined, WebWalletType.SUI, suiWallet.account.publicKey);
            } else {
                connectWallet(phantomAccount ?? '', getPhantomSignature, undefined, WebWalletType.PHANTOM);
            }
        }
    }, [
        metaMaskAccounts,
        metaMaskProvider,
        coinbaseAccounts,
        coinbaseProvider,
        walletConnectAccounts,
        walletConnectProvider,
        wallet,
        walletStep,
        phantomAccount,
        suiWallet.account?.address,
    ]);

    const authenticateUser = async (_token: string, isNewUser = false, restartFlow?: boolean) => {
        try {
            utils.setAuthHeader(_token);
            const _user = await UserModel.fetchMe();
            if (restartFlow) {
                setUser(_user);
                if (wallet && pubAddress) {
                    connectWallet(pubAddress, SignerMap[wallet], _user, WalletMap[wallet], suiWallet.account?.publicKey);
                } else {
                    handleWalletSelect(wallet ?? WalletType.MetaMask);
                }
                return;
            }
            if (_user?.isWeb3EmailSetup === false) {
                setWalletMeta?.((oldData = {}) => ({ ...oldData, id: _token, userId: _user.id }));
                setWalletStep('web3-email-check');
                return;
            }
            dispatch({
                type: ANALYTICS_USER_EVENT,
                data: { eventName: 'USER_LOG_IN', context: { user: _user, type: 'web3', provider: wallet }, source: pathname },
            });
            getAccess(_token, isNewUser, _user);
        } catch (error) {
            // error handling
            console.error(error);
        }
    };

    const getAccess = (token: string, isNewUser: boolean, _user: User, pubAddress?: string) => {
        setWalletMeta?.((oldMeta = {}) => ({ ...oldMeta }));
        if (!appUser) {
            utils.setAccessToken(token);
            if (pubAddress) setAppUser({ ..._user, publicAddress: pubAddress, recentWalletUsed: WalletMap[wallet ?? WalletType.MetaMask] });
            else setAppUser(_user);
        }
        setIsAuthenticated(true);
        lastStep(isNewUser);
    };

    /**
     *  Except phantom, all other current providers run on the ethereum blockchain and function similarly.
     * So, 'metamask' is used to indicate all wallet providers other than phantom which runs on solana.
     */

    const connectWallet = async (
        publicAddress: string,
        getSignature: Function,
        _user?: User,
        provider: WebWalletType = WebWalletType.METAMASK,
        suiPubKey?: Uint8Array,
    ) => {
        if (!publicAddress) return;
        try {
            let res;
            if (appUser || user || _user)
                res = await Web3Model.requestWalletConnect(publicAddress, (appUser?.email ?? user?.email ?? _user?.email ?? '').toLowerCase());
            else res = await Web3Model.getNonce(publicAddress);
            const signature = await getSignature(res.message);
            if (appUser || user || _user) {
                await Web3Model.linkWallet(
                    publicAddress,
                    (appUser?.email ?? user?.email ?? _user?.email ?? '').toLowerCase(),
                    provider === WebWalletType.SUI ? undefined : signature,
                    provider === WebWalletType.PHANTOM ? 'solana' : provider === WebWalletType.SUI ? 'sui' : 'metamask',
                    provider,
                    provider !== WebWalletType.SUI
                        ? undefined
                        : { ...signature, publicKey: typeof suiPubKey === 'object' ? Object.values(suiPubKey) : suiPubKey },
                );
                let authToken;
                setWalletMeta?.((meta = {}) => {
                    authToken = meta?.id;
                    return meta;
                });
                if (!appUser)
                    dispatch({
                        type: ANALYTICS_USER_EVENT,
                        data: { eventName: 'USER_LOG_IN', context: { user: user || _user, type: 'web3', provider }, source: pathname },
                    });
                getAccess(authToken ?? walletMeta?.id ?? '', true, appUser ?? user ?? _user ?? ({} as User), publicAddress);
                return;
            }

            const _token = await Web3Model.verifySignature(
                publicAddress,
                provider === WebWalletType.SUI ? undefined : signature,
                undefined,
                provider === WebWalletType.PHANTOM ? 'solana' : provider === WebWalletType.SUI ? 'sui' : 'metamask',
                provider,
                provider !== WebWalletType.SUI
                    ? undefined
                    : { ...signature, publicKey: typeof suiPubKey === 'object' ? Object.values(suiPubKey) : suiPubKey },
            );
            setPubAddress(publicAddress);

            if (res?.isNewUser) {
                setWalletMeta?.((oldMeta = {}) => ({ ...oldMeta, id: _token.id, userId: _token.userId }));
                setWalletStep('web3-email-check');
                return;
            }
            await authenticateUser(_token.id);
        } catch (error) {
            // error handling
            console.error(error);
            showToast("Something went wrong. Please check if you're logged in to the correct account in your wallet app / extension", 'error');
        }
    };

    const handleEmailExist = useAsyncTask(async (data: Partial<CheckEmailExistFormData>) => {
        try {
            const isExist = await UserModel.checkIfEmailExists(data.email?.toLowerCase() ?? '');
            setWalletMeta?.((oldMeta = {}) => ({
                ...oldMeta,
                setStep: undefined,
                email: data.email,
                prevStep: 'web3-email-check',
                loginHeading: 'It seems you already have a Playground account',
                loginSubheading: 'Enter your password to continue with this email address.',
            }));
            if (isExist) {
                setWalletStep('login');
            } else {
                setWalletStep('signup');
            }
        } catch (error) {
            // error handling
            console.error(error);
        }
    });

    const handleUserSignUp = useAsyncTask(async (data: Partial<SignUpFormData>) => {
        if (get(data, 'error') || get(data, 'errorslug')) return;
        try {
            if (walletMeta?.id && walletMeta?.userId) {
                await authenticate({ token: walletMeta.id });
                const userInfo = await updateUser({
                    ...omit(data, ['error', 'errorslug']),
                    id: walletMeta.userId,
                    slug: data.slug,
                    firstName: data.slug,
                    isWeb3EmailSetup: true,
                    email: data.email?.toLowerCase(),
                });
                setAppUser(userInfo as User);
                setWallet(undefined);
                setWalletMeta?.({});
                registrationComplete();
                await requestEmailVerification(data.email ?? '');

                dispatch({
                    type: ANALYTICS_USER_EVENT,
                    data: {
                        eventName: 'USER_SIGNUP',
                        context: {
                            user: omit(userInfo, ['otpResult', 'firstName', 'isPublished', 'password']),
                            walletProvider: wallet,
                            type: 'web3',
                        },
                        source: pathname,
                    },
                });

                setWalletStep('verifyEmailPrompt');
            }
        } catch (error) {
            // error handling
            console.error(error);
            showToast({ title: get(error, 'response.data.error.message') ?? (error as any).message });
        }
    });

    const handleUserLogIn = useAsyncTask(async (data: Partial<LoginFormData>) => {
        if (get(data, 'error')) return;
        try {
            const { email, password } = data;
            // await userLogin(data as LoginFormData);
            // handleWalletSelect(wallet?.name ?? 'MetaMask');
            const _token = await AuthModel.login({ email: email?.toLowerCase() ?? '', password: password ?? '' });
            setWalletMeta?.((oldData = {}) => ({
                ...oldData,
                // wallet: undefined,
                id: _token.id /* 'abdfaluerdsfdiu' */,
                userId: _token.userId /* 'adbfakdjfd' */,
            }));
            authenticateUser(_token.id, true, true);
        } catch (error) {
            // error handling
            console.error(error);
            // Email not existing is not a very probable scenario.
            const emailExists = data.email && (await UserModel.checkIfEmailExists(data.email));
            showToast({
                title: !emailExists ? MESSAGES.LOGIN_ACCOUNT_DOES_NOT_EXIST_TITLE : MESSAGES.LOGIN_ERROR_TITLE,
                subtitle: !emailExists ? MESSAGES.LOGIN_ACCOUNT_DOES_NOT_EXIST_MESSAGE : MESSAGES.LOGIN_ERROR_MESSAGE,
            });
        }
    });

    const lastStep = (isNewUser: boolean) => {
        setWallet(undefined);
        if (isNewUser) {
            setWalletMeta?.((oldMeta = {}) => ({ ...oldMeta, hideClose: false }));
            // setWalletStep('email-verified');
            setWalletStep('pick-interests');
        } else {
            showToast(MESSAGES.LOGIN_SUCCESS, 'success');
            onDone();
        }
    };

    const deactivateWallet = (_wallet: WalletType) => {
        switch (_wallet) {
            case WalletType.MetaMask:
                metaMask?.deactivate?.();
                break;
            case WalletType.Coinbase:
                // coinbaseWallet.deactivate();
                break;
            case WalletType.WalletConnect:
                walletConnect?.deactivate?.();
                break;
            case WalletType.Phantom:
                phantomProvider?.disconnect();
                break;
            default:
                break;
        }
    };

    const handleError = (error: any) => {
        console.error(error);
        setWalletStep('wallet-error');
    };

    const handleWalletSelect = (walletName: WalletType, appLink?: string) => {
        setWalletMeta?.((oldMeta = {}) => ({ ...oldMeta, showBack: false }));
        setWalletStep('wallet-connecting');
        setWallet(walletName);
        const redirectUrl = (web3State?.getQrCodeApiUrl as Function)?.() || appLink;
        const currProvider = (window as any)?.web3?.currentProvider;
        switch (walletName) {
            case WalletType.MetaMask:
                if (isDeviceSm && !currProvider?.isMetaMask && redirectUrl) window.open(`https://metamask.app.link/dapp/${redirectUrl}`, '_blank');
                else metaMask.activate(getAddChainParameters(parseInt(config.get('DEFAULT_CHAIN_ID') ?? '', 10))).catch(handleError);
                break;
            case WalletType.Coinbase:
                if (isDeviceSm && !currProvider?.isCoinbaseBrowser && redirectUrl)
                    window.open(`https://go.cb-w.com/dapp?cb_url=${encodeURIComponent(redirectUrl)}`, '_blank');
                else coinbaseWallet.activate(getAddChainParameters(parseInt(config.get('DEFAULT_CHAIN_ID') ?? '', 10))).catch(handleError);
                break;
            case WalletType.WalletConnect:
                if (!isDeviceSm) deactivateWallet(WalletType.WalletConnect);
                walletConnect.activate(parseInt(config.get('DEFAULT_CHAIN_ID') ?? '', 10)).catch(handleError);
                break;
            case WalletType.Phantom:
                phantomProvider?.connect().then(({ publicKey }) => setPhantomAccount(publicKey.toBase58()));
                break;
            case WalletType.Sui:
                suiWallet.select('Sui Wallet');
                break;
            default:
                console.error('Wallet not found');
        }
    };

    const handleErrorBackClick = () => {
        setWalletMeta?.((meta) => ({ ...meta, hideClose: false }));
        setWalletStep('wallet-select');
    };

    return {
        handleWalletSelect,
        handleEmailExist,
        handleUserSignUp,
        handleUserLogIn,
        handleErrorBackClick,
        walletMeta,
        setWalletMeta,
        walletStep,
        setWalletStep,
    };
}

export default useWalletConnect;
