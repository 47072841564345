import React, { forwardRef } from 'react';
import AliceCarousel, { Props as AliceCarouselProps } from 'react-alice-carousel';

import 'react-alice-carousel/lib/alice-carousel.css';

export type CarouselRef = AliceCarousel;

export interface CarouselProps extends AliceCarouselProps {}

const DEFAULT_PROPS: AliceCarouselProps = {
    animationDuration: 300,
    disableButtonsControls: true,
    disableDotsControls: true,
};

const Carousel = forwardRef<AliceCarousel, CarouselProps>((props, ref) => {
    const carouselProps = { ...DEFAULT_PROPS, ...props };

    return <AliceCarousel {...carouselProps} ref={ref} />;
});

export default Carousel;
