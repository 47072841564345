import { AppBar, Box, IconButton, Portal, Theme, Toolbar, createStyles, makeStyles } from '@material-ui/core';
import SVGCIcon from 'Components/SVGComponents/SVGCIcon';
import React, { FC, useMemo, useState } from 'react';
import { APP_HEADER_HEIGHT } from '.';
import { BORDER_WHITE } from 'Theme/themeConstants';

import PG_LOGO from 'Assets/images/PLAYGROUND_BRANDMARK_WHITE.svg';
import { Link } from 'react-router-dom';
import { APP_ROUTES } from 'Routes';
import PgIcon from 'Components/PgIcon';
import useMobileMenuDialog from 'Dialogs/MobileMenu/useMobileMenuDialog';
import useSearchDialog from 'Dialogs/MobileMenu/SearchDialog';
import { useStoreState } from 'Stores';
import NotifButton from 'Features/Notifications/NotifButton';
import UserAvatar from 'Components/UserAvatar';
import UserModel from 'Models/User';
import { generatePath } from 'react-router';
import helpers from 'Utils/helpers';
import LeftMobileDrawer from 'Features/MobileDrawers/LeftMobileDrawer';
import RightMobileDrawer from 'Features/MobileDrawers/RightMobileDrawer';

const MobileNav: FC = () => {
    const classes = useStyles();

    const { openDialog, DialogComponent } = useMobileMenuDialog();
    const { toggleShow, SearchDialog } = useSearchDialog();

    const [isLeftDrawerOpen, setIsLeftDrawerOpen] = useState(false);
    const [isRightDrawerOpen, setIsRightDrawerOpen] = useState(false);

    const { appUser } = useStoreState(({ AuthStore: { appUser } }) => ({ appUser }));

    const { isLoggedIn, userProfileImage, userProfileUrl } = useMemo(
        () => ({
            isLoggedIn: !!appUser?.id,
            userProfileImage: helpers.getPictureUrl(appUser?._profileImages?.[0]),
            userProfileUrl: generatePath(APP_ROUTES.USER_DETAIL.path, { id: appUser?.slug ?? appUser?.id ?? '#' }),
        }),
        [appUser?.slug, appUser?.id, appUser?._profileImages],
    );

    const handleMenuClick = () => {
        if (isLoggedIn) setIsLeftDrawerOpen(true);
        else openDialog();
    };

    return (
        <AppBar position="sticky" className={classes.root}>
            <Toolbar className={classes.toolBar}>
                {/* <IconButton onClick={openDialog} className={classes.menuButton}> */}
                <IconButton onClick={handleMenuClick} className={classes.menuButton}>
                    <SVGCIcon icon="icon-menu" />
                </IconButton>
                <Link to={APP_ROUTES.HOME_PAGE.path} className={classes.link}>
                    <img src={PG_LOGO} alt="pg-logo" className={classes.logo} />
                </Link>
                <Box flex={1}></Box>
                <Box display="flex" alignItems="center" gridGap={12}>
                    <IconButton onClick={toggleShow} className={classes.searchBtn}>
                        <PgIcon color="contrast" icon="icon-search" styleClass={classes.searchIcon} />
                    </IconButton>
                    {isLoggedIn && (
                        <>
                            <NotifButton />
                            {/* <Link to={userProfileUrl} className={classes.link}>
                                <UserAvatar name={UserModel.getUserName(appUser)} size="30px" url={userProfileImage} />
                            </Link> */}
                            <IconButton onClick={() => setIsRightDrawerOpen(true)} className={classes.menuButton}>
                                <UserAvatar name={UserModel.getUserName(appUser)} size="30px" url={userProfileImage} />
                            </IconButton>
                        </>
                    )}
                </Box>
            </Toolbar>
            <Portal>
                {DialogComponent}
                {SearchDialog}
                <LeftMobileDrawer open={isLeftDrawerOpen} onClose={() => setIsLeftDrawerOpen(false)} />
                <RightMobileDrawer open={isRightDrawerOpen} onClose={() => setIsRightDrawerOpen(false)} />
            </Portal>
        </AppBar>
    );
};

export default MobileNav;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
            height: APP_HEADER_HEIGHT,
            borderBottom: BORDER_WHITE,
        },
        toolBar: { minHeight: APP_HEADER_HEIGHT, gap: theme.spacing(2) },
        menuButton: { padding: 0 },
        link: { height: 30 },
        logo: { height: '100%' },
        searchBtn: { padding: 0 },
        searchIcon: { fontSize: 20 },
    }),
);
